<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Spinner from "vue-simple-spinner";
import BankAccountForm from './bank-account-form.vue';
//import { getBeneficiary, createBeneficiary } from "@/state/helpers";
import {
  FETCH_REMITTANCES_BANK_ACCOUNT,
  //FETCH_BENEFICIARY_TABLE,
  // CREATE_BENEFICIARY,
//   FETCH_BANKS_LIST,

  // FETCH_ALL_PAYMENT_CHANNEL
} from "@/state/actions.type";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import * as cloneDeep from "lodash.clonedeep";
import UpdateBankAccountForm from './update-bank-account-form.vue';
//const alphAndSpaceValidator = helpers.regex('alphaAndSpace', /^[a-zA-Z ]*$/);
export default {
  components: {
    Layout,
    Spinner,
    BankAccountForm,
    PageHeader,
    UpdateBankAccountForm,
  },

  data() {
    return {
      errorMessage: "",
      isAddBankAccountModalOpen: false,
      isUpdateBankAccountModalOpen: false,
      bankdetails:"",
      successfulMessage: false,
      createError: false,
      mor_filter: "",
      submitted: false,
      typesubmit: false,
      bankno: "",
      banksListCreds: [],
      bankAccountsData: [],
      chaiBankAccount: {
        bank_id: "",
        currency: "",
        bank_name: "",
        remittance_source: "",
        account_number: "",
        account_type: "",
        ifsc: ""
      },

      fields: [
        { key: "show_details", sortable: false, label: 'Options', tooltip: "Options" },
        {
          key: "active",
          label: "Status",
          tooltip: "Status",
        },

        {
          key: "default",
          label: "Default",
          tooltip: "Default",
        },
        {
          key: "currency",
          sortable: true,
          label: "Currency",
          tooltip: "Currency",
        },
        {
          key: "first_name",
          sortable: true,
          label: "First Name",
          tooltip: "First Name",
        },
        {
          key: "last_name",
          sortable: true,
          label: "Last Name",
          tooltip: "Last Name",
        },
        {
          key: "phone_number",
          sortable: true,
          label: "Phone Number",
          tooltip: "Phone Number",
        },
        {
          key: "bank_id",
          sortable: true,
          label: "Bank Id",
          tooltip: "Bank Id",
        },
        
        {
          key: "bank_name",
          sortable: true,
          label: "Bank Name",
          tooltip: "Bank Name",
        },
        
        {
          key: "account_number",
          sortable: true,
          label: "Account Number",
          tooltip: "Account Number",
        },
        {
          key: "account_type",
          sortable: true,
          label: "Account Type",
          tooltip: "Account Type",
        },
        {
          key: "ifsc",
          sortable: true,
          label: "IFSC Code",
          tooltip: "IFSC Code",
        },
        {
          key: "branch",
          sortable: true,
          label: "Branch",
          tooltip: "Branch",
        },
        {
          key: "transit_code",
          sortable: true,
          label: "Transit Code",
          tooltip: "Transit Code",
        },
        {
          key: "sort_code",
          sortable: true,
          label: "Sort Code",
          tooltip: "Sort Code",
        },
        {
          key: "swift",
          sortable: true,
          label: "Swift Code",
          tooltip: "Swift Code",
        },
        {
          key: "bsb_code",
          sortable: true,
          label: "Bsb Code",
          tooltip: "Bsb Code",
        },
        {
          key: "routing_number",
          sortable: true,
          label: "Routing Number",
          tooltip: "Routing Number",
        },
        {
          key: "iban",
          sortable: true,
          label: "Iban",
          tooltip: "Iban",
        },
        {
          key: "remittance_source",
          sortable: true,
          label: "Remittance Source",
          tooltip: "Remittance Source",
        },
        {
          key: "wing_money_number",
          sortable: true,
          label: "Wing Money Number",
          tooltip: "Wing Money Number",
        },
        {
          key: "card_number",
          sortable: true,
          label: "Card Number",
          tooltip: "Card Number",
        }
        



      ],

      // totalRows: 1,
      // currentPage: 1,
      // perPage: 10,
      // pageOptions: [10, 25, 50, 100],
    };
  },
  created() {
    this.loadData();
    this.bankAccountsData = cloneDeep(this.getbankAccountsData);
    // this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`,{
    //   country: [],
    //   paymentMethod: "",
    //   paymentType: "PAYOUT"
    // });
    this.isAddBankAccountModalOpen = false;
    this.isUpdateBankAccountModalOpen = false;
  },

  computed: {
    // banksList() {
    //   return this.$store.state.beneficiary.banks_list;
    // },

    title() {
      return this.$t("menuitems.remittance.list.bank_accounts");
    },
    items() {
      return [
        {
          text: this.$t("menuitems.remittance.list.remittance"),
          active: true
        },
        {
          text: this.$t("menuitems.remittance.list.bank_accounts"),
          active: true,
        },
      ];
    },

    getbankAccountsData() {
      return this.$store.state.remittanceBankAccounts.content.content;
    },

    isFetchingTransactions() {
      return this.$store.state.remittanceBankAccounts.isFetchingData;
    },

    rows() {
      return this.$store.state.remittanceBankAccounts.total_elements;
    },

    // payoutChannels() {
    //   let channels = this.$store.state.paymentChannels.channels;
    //   let channelList = []
    //   for(let psp in channels) {
    //     if(channels[psp].onboarding_status.status == "Completed") {
    //       channelList.push(psp)
    //     }
    //   }
    //   return channelList;
    //   // return Object.keys(this.$store.state.paymentChannels.channels);
    // }
  },

  validations: {
    chaiBankAccount: {
      bank_id: { required },
      currency: { required },
      bank_name: { required },
      remittance_source: { required},
      account_number: { required },
      account_type: { required },
      ifsc: { required }
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    perPage: function (val) {
      this.loadData();
    },
    // eslint-disable-next-line no-unused-vars
    currentPage: function (val) {
      this.loadData();
    },
    banksList() {
      this.banksListCreds = cloneDeep(this.banksList);
    },

    getbankAccountsData() {
      this.bankAccountsData = cloneDeep(this.getbankAccountsData);
    },
    // 'chaiBeneficiary.payout_channel': function(value) {
    //   if(value) {
    //     this.$store.dispatch(`beneficiary/${FETCH_BANKS_LIST}`, value);
    //   }
    //   if(value == 'GBPRIMEPAY_PAYOUT') {
    //     this.chaiBeneficiary.bank_account_type = 'BANK';
    //   } else {
    //     this.chaiBeneficiary.bank_account_type = '';
    //   }
    // },
  },

  mounted() {
    // Set the initial number of items
    this.bankAccountsData = cloneDeep(this.getbankAccountsData);
    this.totalRows = this.bankAccountsData ? this.bankAccountsData.length : 1;
    //this.loadData();
  },

  methods: {
    // ...getBeneficiary,
    // ...createBeneficiary,

    // selectbankcode(){
    //   this.chaiBeneficiary.bank_no = event.target.options[event.target.selectedIndex].dataset.key;
    // },

    loadData() {
      this.$store.dispatch(`remittanceBankAccounts/${FETCH_REMITTANCES_BANK_ACCOUNT}`, {
        pageIndex: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
      });
    },

    closeBankAccountModal() {
      this.isAddBankAccountModalOpen = false;
      this.loadData();
    },
    closeUpdateBankAccountModal() {
      this.isUpdateBankAccountModalOpen = false;
      this.loadData();
    },

    openAddBankAccountdModal() {
    this.isAddBankAccountModalOpen = true;
    },
    openUpdateBankDetails(bankAccountDetails){
      console.log(bankAccountDetails);
      this.bankdetails = bankAccountDetails;
      this.isUpdateBankAccountModalOpen = true;

    },


    // addVendors() {
    //   this.submitted = true;
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   }
      
      
    //   const addBankAccount = {
    //     //payout_channel: this.chaiBeneficiary.payout_channel,
    //     bank_id: this.chaiBankAccount.bank_id,
    //     currency: this.chaiBankAccount.currency,
    //     bank_name: this.chaiBankAccount.bank_name,
    //     remittance_source: this.chaiBankAccount.remittance_source,
    //     account_number: this.chaiBankAccount.account_number,
    //     account_type: this.chaiBankAccount.account_type,
    //     ifsc: this.chaiBankAccount.ifsc,
    //     branch: this.chaiBankAccount.branch,
    //     transit_code:this.chaiBankAccount.transit_code,
    //     sort_code: this.chaiBankAccount.sort_code,
    //     swift:this.chaiBankAccount.swift,
    //     bsb_code: this.chaiBankAccount.bsb_code,
    //     routing_number:this.chaiBankAccount.routing_number,
    //     iban:this.chaiBankAccount.iban,
    //     wing_money_number:this.chaiBankAccount.wing_money_number,
    //     card_number:this.chaiBankAccount.card_number,
    //     //fee_payer: this.chaiBeneficiary.payout_channel == 'APPOTAPAY_PAYOUT' ? this.chaiBeneficiary.fee_payer : "SENDER",
    //   };
    //   this.submitted = false;

    //   this.$store
    //     .dispatch(`beneficiary/${CREATE_BENEFICIARY}`, addBankAccount)
    //     .then((result) => {
    //       setTimeout(() => {
    //         this.successfulMessage = false;
    //         this.createError = false;
    //         this.resetresetBankAccount();
    //         this.loadData();
    //       }, 3000);

    //       if (result.is_success == "false") {
    //         (this.errorMessage = result.message), (this.createError = true);
    //       } else {
    //         this.successfulMessage = true;
    //       }
    //     })
    //     .catch((error) => {
    //       this.createError = true;
    //       const errorObject = JSON.parse(error.message)
    //       this.errorMessage = errorObject.message
    //       setTimeout(() => {
    //         this.resetresetBankAccount();
            
    //       }, 3000);
    //       console.log(error);
    //     });
    //},

    hideModal() {
      this.submitted = false;
      
      this.resetresetBankAccount();
    },

    openVendorsModal() {
      this.submitted = false;
      this.resetresetBankAccount();

    //   if(this.payoutChannels.length == 1) {
    //     this.chaiBeneficiary.payout_channel = this.payoutChannels[0];
    //   }

    },

    resetBankAccount() {
      this.chaiBankAccount = {
        //payout_channel: "",
        bank_id: "",
        currency: "",
        bank_name: "",
        remittance_source: "",
        account_number: "",
        account_type: "",
        ifsc: "",
        branch:"",
        transit_code:"",
        sort_code:"",
        swift:"",
        bsb_code:"",
        routing_number:"",
        iban:"",
        wing_money_number:"",
        card_number:"",

      };
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterTransactionByMor() {
      if (this.mor_filter == "") {
        this.loadData();
      } else {
        this.loadData();
      }
    },

    fetchTransactionOnEmptyFilter() {
      if (this.mor_filter == "") {
        this.loadData();
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.chaiBankAccount[name];
      return $dirty ? !$error : null;
    },
  },

  filters: {
    formateDate(value) {
      return moment(value).format("MMMM DD YYYY, h:mm:ss a");
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex flex-row justify-content-between my-4">
          <div id="tickets-table_length" class="dataTables_length">
            <!-- <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;Entries
            </label> -->
          </div>
          <div>
            <button
              class="btn btn-primary btn-md"
              @click="openAddBankAccountdModal"
            >
              {{ $t("views.remittance.add-bank-account") }}
            </button>
          </div>
          <div class="col-sm-12 col-md-4" style="display: none">
            <b-input-group>
              <b-form-input
                type="search"
                v-model="mor_filter"
                @keyup="fetchTransactionOnEmptyFilter"
                @keyup.enter="filterTransactionByMor"
                class="form-control form-control-sm ml-2"
                placeholder="Search Vendors"
              ></b-form-input>
              <b-input-group-append>
                <button
                  class="btn btn-primary btn-block btn-sm"
                  @click="filterTransactionByMor"
                >
                  {{ $t("views.payments.transactions.search") }}
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>


        <div class="table-responsive text-center table-custom">
          <b-table
            id="my-table"
            class="table-centered"
            :items="bankAccountsData"
            :fields="fields"
            responsive="lg"
            :per-page="perPage"
            :bordered="true"
            sticky-header="500px"
            show-empty
            :empty-text="$t('views.payments.transactions.no-records')"
            busy.sync="true"
            hover
          >
            <!-- <template #cell(account_number)="data">
              <router-link
                :to="'/payouts/vendor-accounts?uuid=' + data.item.uuid"
                class="card-link text-decoration-underline"
              >
                {{ $t("views.payouts.view-details") }}
              </router-link>
            </template> -->

            <template v-slot:head()="data">
              <span v-b-tooltip.hover.top :title="data.field.tooltip">
                {{ data.label }}
              </span>
            </template>


            <template #cell(show_details)="row">
                      <b-dropdown size="sm" class="ml-1" variant="light">
                        <template v-slot:button-content>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                          </svg>
                        </template>
                        <b-dropdown-item-button @click="openUpdateBankDetails(row.item)">Update</b-dropdown-item-button>
                      </b-dropdown>
                    </template>

            <template v-slot:cell(active)="row">
                  <div
                    class="badge font-size-12"
                    :class="{
                      'badge-soft-danger':
                        `${row.value}` === 'false',
                        
                      'badge-soft-success': `${row.value}` === 'true'
      
                    }"
                  >
                    {{ row.value === true ? "Active" : "Inactive"}}
                  </div>
                </template>

            <template v-slot:cell(default)="row">
                  <div
                    class="badge font-size-12"
                    :class="{
                      'badge-soft-danger':
                        `${row.value}` === 'false',
                        
                      'badge-soft-success': `${row.value}` === 'true'
      
                    }"
                  >
                    {{ row.value === true ? "Default" : "Not Default"}}
                  </div>
                </template>

          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>

        <div>
          <b-modal
            centered
            title="Bank Account Creation"
            v-model="createError"
            title-class="text-dark font-18"
            no-close-on-backdrop
            hide-footer
          >
            <p>{{ errorMessage }}</p>
            <p>
              <!-- {{ $t("views.payouts.beneficiary-fail") }} -->
              Bank Accounts creation failed
            </p>
          </b-modal>
        </div>

        <div>
          <b-modal
            centered
            title="Bank Account Creation"
            v-model="successfulMessage"
            title-class="text-dark font-18"
            no-close-on-backdrop
            hide-footer
          >
            <div class="d-flex justify-content-around">
              <img src="@/assets/images/success.png" alt height="100" />
            </div>
            <p class="d-flex justify-content-around">
              <!-- {{ $t("views.payouts.beneficiary-success") }} -->
              Bank Account creation Successful
            </p>
          </b-modal>
        </div>

        <div class="row">
          <div class="col">
            <!-- <div
              class="dataTables_paginate paging_simple_numbers float-right"
            >
              <ul class="pagination pagination-rounded"> -->
                <!-- pagination -->
                <!-- <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div> -->
          </div>
        </div>

      <div v-if="this.isAddBankAccountModalOpen">
        <!-- Right Sidebar -->
            <div class="right-bar"
              :class="{
                'active': isAddBankAccountModalOpen
              }">
              <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
              <div data-simplebar class="h-100" style="overflow-x: auto;">
                <div class="rightbar-title">
                  <div class="right-top-section">
                    <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                    <div class="card-body-custom">
                      <i class="mdi mdi-close noti-icon float-right cursor-pointer" style="position: relative;z-index: 1;right: 15px;top: 30px;font-size: 20px;" @click="closeBankAccountModal"></i>
                      <div class="media-body media-body-custom">
                        <p class="d-inline-block pl-4" style="font-size: 20px;margin-top: 30px;margin-bottom: 30px;">
                          Add Bank Account
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4">
                  <!-- <form id="modal" action="#" @submit.prevent="createRecon">
                    <div class="row">
                      <div class="col-lg-12">
                      </div>
                    </div>
                    <div class="row pb-3">
                    </div>
                    <div class="text-left" style="margin-top: 60px;">
                      <button
                        type="submit"
                        class="btn btn-primary dark-bg ml-1 col-4"
                      >
                        Submit
                      </button>
                      <b-button class="ml-2 col-4" variant="light" @click="closePaymentLinkModal"
                        >Cancel</b-button>
                    </div>
                  </form> -->



                  <BankAccountForm  v-if="isAddBankAccountModalOpen" /> 


                </div>
              </div>
              <!-- end slimscroll-menu-->
            </div>
              <!-- Right bar overlay-->
            <div @click="closeBankAccountModal" class="rightbar-overlay"
              :class="{
                'active': isAddBankAccountModalOpen
            }"></div>
        </div>
        <div v-else>

          <!-- Right Sidebar -->
            <div class="right-bar"
              :class="{
                'active': isUpdateBankAccountModalOpen
              }">
              <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
              <div data-simplebar class="h-100" style="overflow-x: auto;">
                <div class="rightbar-title">
                  <div class="right-top-section">
                    <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                    <div class="card-body-custom">
                      <i class="mdi mdi-close noti-icon float-right cursor-pointer" style="position: relative;z-index: 1;right: 15px;top: 30px;font-size: 20px;" @click="closeUpdateBankAccountModal"></i>
                      <div class="media-body media-body-custom">
                        <p class="d-inline-block pl-4" style="font-size: 20px;margin-top: 30px;margin-bottom: 30px;">
                          Update Bank Account
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4">
                  <!-- <form id="modal" action="#" @submit.prevent="createRecon">
                    <div class="row">
                      <div class="col-lg-12">
                      </div>
                    </div>
                    <div class="row pb-3">
                    </div>
                    <div class="text-left" style="margin-top: 60px;">
                      <button
                        type="submit"
                        class="btn btn-primary dark-bg ml-1 col-4"
                      >
                        Submit
                      </button>
                      <b-button class="ml-2 col-4" variant="light" @click="closePaymentLinkModal"
                        >Cancel</b-button>
                    </div>
                  </form> -->



                  
                  <UpdateBankAccountForm :bankdetails="this.bankdetails" v-if="isUpdateBankAccountModalOpen" /> 


                </div>
              </div>
              <!-- end slimscroll-menu-->
            </div>
              <!-- Right bar overlay-->
            <div @click="closeUpdateBankAccountModal" class="rightbar-overlay"
              :class="{
                'active': isUpdateBankAccountModalOpen
            }"></div>

        </div>

        

            
      </div>
    </div>


    
         
  </Layout>
</template>

<style scoped>

.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 600px;
  right: -600px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.cursor-pointer{
  cursor: pointer;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414;;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
.avatar-custom{
    height: 70px;
    width: 95px;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
</style>