<script>
// import VuePhoneNumberInput from "vue-phone-number-input";
// import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
//import * as cloneDeep from "lodash.clonedeep";
import {
  POST_REMITTANCES_BANK_ACCOUNT,
  FETCH_BANK_LIST
} from "@/state/actions.type";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
//import * as cloneDeep from "lodash.clonedeep";
export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;
export const CHECKOUT_BASE_URL = process.env.VUE_APP_CHECKOUT_BASE_URL;
export default {
    components: {
        VuePhoneNumberInput,
        // DatePicker
    },
    props: {
        isAddBankAccountModalOpen: Boolean
    },
    validations: {
        chaiPayBankDetails: {
            country: { required },
            bank_id:{},
            currency:{required},
            bank_name:{required},
            // remittance_source:{required},
            // remittance_purpose:{},
            account_number:{required},
            account_type:{required},
            ifsc:{},
            branch:{},
            transit_code:{},
            sort_code:{},
            swift:{},
            bsb_code:{},
            routing_number:{},
            iban:{},
            wing_money_number:{},
            card_number:{},
            first_name:{required},
            last_name:{required},
            phone_number:{required}
        },
    },
    data() {
        return {
            orderLink: "",
            typesubmit: false,
            max: 50,
            add_fields:false,
            add_fields_data: [{"key": "", "value": ""}],
            add_fields_error:false,
            timeOptions: [],
            unitOptions: [],
            bank_code_number:null,
            url_reference: false,
            checkout_base_url: CHECKOUT_BASE_URL,
            phoneNumber: "",
            phoneNumberVal: null,
            bankList:{},
            chaiPayBankDetails: {
                //amount: "",
                
                country: "",
                bank_id:"",
                currency:"",
                bank_name:"",
                // remittance_source:"",
                // remittance_purpose:"",
                account_number:"",
                account_type:"",
                ifsc:"",
                branch:"",
                transit_code:"",
                sort_code:"",
                swift:"",
                bsb_code:"",
                routing_number:"",
                iban:"",
                wing_money_number:"",
                card_number:"",
                first_name:"",
                last_name:"",
                phone_number:"",
                phone_country:"",
                // active:true,
                default:false




            },
        }
    },
    async created() {
        this.chaiPayBankDetails.currency = this.convertCountryToCurrency;
        this.chaiPayBankDetails.country = this.$store.state.auth.currentUser.country;
        this.loadData();
    },
    watch: {
        phoneNumberVal: function (val) {
            this.chaiPayBankDetails.phone_number = (val && val.countryCallingCode && val.phoneNumber) ? `${val.phoneNumber.replace(/ /g,'')}` : ""
            this.chaiPayBankDetails.phone_country = (val && val.countryCallingCode) ? `${val.countryCallingCode.replace(/ /g,'')}` : ""
            // this.chaiPayMerchantDetails.billing_details_billing_phone = val
            //   ? val.formattedNumber
            //   : "";
        },
        'chaiPayBankDetails.country'(value) {
            switch (value) {
                case 'VN':
                this.chaiPayBankDetails.currency = "VND";
                break;
                case 'TH':
                this.chaiPayBankDetails.currency = "THB";
                break;
                case 'SG':
                this.chaiPayBankDetails.currency = "SGD";
                break;
                case 'ID':
                this.chaiPayBankDetails.currency = "IDR";
                break;
                case 'PH':
                this.chaiPayBankDetails.currency = "PHP";
                break;
                case 'KR':
                this.chaiPayBankDetails.currency = "KRW";
                break;
                default:
                break;
            }
        },
        
    },
    computed: {
        convertCountryToCurrency() {
            return this.$store.getters["auth/getConvertCountry"];
        },
        
    },
    methods: {
        closeBankAccountModal() {
            document.querySelector('.mdi-close').click();
        },
        fetchBankAccounts(){
            return this.$store.state.remittanceBankAccounts.content.content;
        },
        resetForm() {
            this.$v.chaiPayBankDetails.$reset();
            // this.$v.chaiPayMerchantDetails.val()
            this.orderLink = "";
        },
        loadData(){
            this.$store.dispatch(`remittanceBankAccounts/${FETCH_BANK_LIST}`)
            .then((response) => {
                console.log("res", response)
                this.bankList = response
                
            })
            .catch((error) => {
            // error
            console.log("error", error);
            });

        },
        resetCreateBankAccountForm() {
            this.chaiPayBankDetails = {
                
                currency: this.convertCountryToCurrency,
                country: this.$store.state.auth.currentUser.country,
                bank_id:"",
                bank_name:"",
                // remittance_source:"",
                // remittance_purpose:"",
                account_number:"",
                account_type:"",
                ifsc:"",
                branch:"",
                transit_code:"",
                sort_code:"",
                swift:"",
                bsb_code:"",
                routing_number:"",
                iban:"",
                wing_money_number:"",
                card_number:"",
                first_name:"",
                last_name:"",
                phone_number:"",
                phone_country:"",
                active:true,
                default:false
                
            };
            
        },
        submitBankAccount(event) {
            this.typesubmit = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const addBankAccountDetails = {
                currency: event.target.currency.value,
                country: event.target.country.value,
                bank_id: parseInt(this.bank_code_number),
                bank_name: event.target.bank_name.value,
                // remittance_source: event.target.remittance_source.value,
                // remittance_purpose: event.target.remittance_purpose.value,
                account_number: event.target.account_number.value,
                account_type: event.target.account_type.value,
                ifsc: event.target.ifsc.value,
                branch: event.target.branch.value,
                transit_code: event.target.transit_code.value,
                sort_code: event.target.sort_code.value,
                swift: event.target.swift.value,
                bsb_code: event.target.bsb_code.value,
                routing_number: event.target.routing_number.value,
                iban: event.target.iban.value,
                wing_money_number: event.target.wing_money_number.value,
                card_number: event.target.card_number.value,
                first_name: event.target.first_name.value,
                last_name: event.target.last_name.value,
                phone_number: this.chaiPayBankDetails.phone_number,
                phone_country: this.chaiPayBankDetails.phone_country,
                active:true,
                default: event.target.default.checked
            };
            // Dispatch action to add payment links
            this.$store.dispatch(`remittanceBankAccounts/${POST_REMITTANCES_BANK_ACCOUNT}`, addBankAccountDetails)
            .then(() => {
                console.log("ksdkadn")
            //this.orderLink = response.payment_link;
            this.$notify({
                type: "success",
                text: "Bank account generated successfully.",
                closeOnClick: true,
            });
            setTimeout(() => {
                this.resetCreateBankAccountForm();
                this.fetchBankAccounts();
            }, 500);
            this.closeBankAccountModal();
            })
            .catch((error) => {
            // error
            console.log("error", error);
            this.$notify({
                type: "error",
                text: this.$store.state.notification.message,
                closeOnClick: true,
                });
            });
        },

        setbank(event){
            this.bankList.content && this.bankList.content.data.master_code_list.map((val)=> {
                if(val.label === event){
                    this.bank_code_number = val.code
                }

            })
            
            //this.bank_code_number = bank;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.chaiPayBankDetails[name];
            return $dirty ? !$error : null;
        },
        
    }
};
</script>

<template>
    <div>
        <form
            v-show="!orderLink"
            class="row"
            @submit.prevent="submitBankAccount"
        >

        <div class="col-lg-6">
                    <div class="form-group">
                        <label>
                        <!-- <i class="mdi mdi-account mr-2"></i> -->
                        Default Account </label
                        >
                        <input
                            v-model.trim="chaiPayBankDetails.default"
                            type="checkbox"
                            class=" mx-2 mt-4 form-group"
                            name="default"
                        />
                        <!-- <div
                            v-if="$v.bankdetails.active.$error"
                            class="invalid-feedback"
                        > -->
                        <!-- {{
                            "Max 30 characters are allowed"
                        }} -->
                        <!-- </div> -->
                    </div>
        </div>

        <div class="col-lg-12">
                <div class="form-group position-relative">
                    <label
                    > Country Code
                    <span class="text text-danger">*</span></label
                    >
                    <img
                        v-if="chaiPayBankDetails.country == 'VN'"
                        class="flag_images"
                        src="@/assets/images/flags/vietnam1.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.country == 'TH'"
                        class="flag_images"
                        src="@/assets/images/flags/thailand.jpeg"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.country == 'SG'"
                        class="flag_images"
                        src="@/assets/images/flags/singapore.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.country == 'ID'"
                        class="flag_images"
                        src="@/assets/images/flags/indonesia.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.country == 'PH'"
                        class="flag_images"
                        src="@/assets/images/flags/philippines.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.country == 'KR'"
                        class="flag_images"
                        src="@/assets/images/flags/korea.jpg"
                        alt=""
                    />
                    <b-form-select
                        name="country"
                        v-model="chaiPayBankDetails.country"
                        class="form-control custom-select-for-flag"
                    >
                        <b-form-select-option value="ID">{{
                            "ID"
                        }}</b-form-select-option>
                        <b-form-select-option value="PH">{{
                            "PH"
                        }}</b-form-select-option>
                        <b-form-select-option value="SG">{{
                            "SG"
                        }}</b-form-select-option>
                        <b-form-select-option value="TH">{{
                            "TH"
                        }}</b-form-select-option>
                        <b-form-select-option value="VN">{{
                            "VN"
                        }}</b-form-select-option>
                        <b-form-select-option value="KR">{{
                            "KR"
                        }}</b-form-select-option>
                    </b-form-select>
                    <div
                        v-if="$v.chaiPayBankDetails.country.$error || $v.chaiPayBankDetails.country.$error"
                        class="invalid-feedback"
                    >
                        {{
                            "This value is required"
                        }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group position-relative">
                    <label
                    >{{ $t("views.payment_links.currency")
                    }}<span class="text text-danger">*</span></label
                    >
                    <img
                        v-if="chaiPayBankDetails.currency == 'VND'"
                        class="flag_images"
                        src="@/assets/images/flags/vietnam1.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.currency == 'THB'"
                        class="flag_images"
                        src="@/assets/images/flags/thailand.jpeg"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.currency == 'SGD'"
                        class="flag_images"
                        src="@/assets/images/flags/singapore.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.currency == 'IDR'"
                        class="flag_images"
                        src="@/assets/images/flags/indonesia.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.currency == 'PHP'"
                        class="flag_images"
                        src="@/assets/images/flags/philippines.png"
                        alt=""
                    />
                    <img
                        v-if="chaiPayBankDetails.currency == 'KRW'"
                        class="flag_images"
                        src="@/assets/images/flags/korea.jpg"
                        alt=""
                    />
                    <b-form-select
                        name="currency"
                        v-model="chaiPayBankDetails.currency"
                        class="form-control custom-select-for-flag"
                    >
                        <b-form-select-option value="IDR">{{
                            "IDR"
                        }}</b-form-select-option>
                        <b-form-select-option value="PHP">{{
                            "PHP"
                        }}</b-form-select-option>
                        <b-form-select-option value="SGD">{{
                            "SGD"
                        }}</b-form-select-option>
                        <b-form-select-option value="THB">{{
                            "THB"
                        }}</b-form-select-option>
                        <b-form-select-option value="VND">{{
                            "VND"
                        }}</b-form-select-option>
                        <b-form-select-option value="KRW">{{
                            "KRW"
                        }}</b-form-select-option>
                    </b-form-select>
                    <div
                        v-if="$v.chaiPayBankDetails.currency.$error || $v.chaiPayBankDetails.currency.$error"
                        class="invalid-feedback"
                    >
                        {{
                            "This value is required"
                        }}
                    </div>
                </div>
            </div>
            <div class="my-2 ml-2"><h6 style="font-weight:750; font-size: 16px;">Personal Info </h6></div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    First Name <span class="text text-danger">*</span></label
                    >
                    <div class="input-group">
                        <input
                            v-model.trim="$v.chaiPayBankDetails.first_name.$model"
                            type="text"
                            class="form-control"
                            placeholder="Enter first name"
                            name="first_name"
                            :maxlength="max"
                            :class="{
                                'is-invalid':
                                typesubmit && $v.chaiPayBankDetails.first_name.$error,
                            }"
                        />
                        <div
                            class="input-group-addon"
                            v-text="max - chaiPayBankDetails.first_name.length"
                            ></div>
                        <div
                            v-if="$v.chaiPayBankDetails.first_name.$error"
                            class="invalid-feedback"
                        >
                        {{
                            "This field is required"
                        }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Last Name <span class="text text-danger">*</span></label
                    >
                    <div class="input-group">
                        <input
                            v-model.trim="$v.chaiPayBankDetails.last_name.$model"
                            type="text"
                            class="form-control"
                            placeholder="Enter last name"
                            name="last_name"
                            :maxlength="max"
                            :class="{
                                'is-invalid':
                                typesubmit && $v.chaiPayBankDetails.last_name.$error,
                            }"
                        />
                        <div
                            class="input-group-addon"
                            v-text="max - chaiPayBankDetails.last_name.length"
                        ></div>
                        <div
                            v-if="$v.chaiPayBankDetails.last_name.$error"
                            class="invalid-feedback"
                        >
                        {{
                            "This field is required"
                        }}
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="col-lg-12">
                <div class="form-group">
                  <label
                    >
                    {{ $t("views.payment_links.customer_phone") }}<span class="text text-danger">*</span></label
                  >
                  <VuePhoneNumberInput
                    v-model="phoneNumber"
                    id='VuePhoneNumber'
                    required=true
                    @update="phoneNumberVal = $event"
                    :default-country-code="this.chaiPayBankDetails.country"
                    :only-countries="['IN', 'ID', 'MY', 'PH', 'SG', 'KR', 'TH', 'VN', 'TW']"
                  />
                  <!-- <div
                    v-if="typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_phone.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_phone.required">{{ 'This value is required.' }}</span>
                  </div> -->
                </div>
              </div>

            <div class="my-2 ml-2"><h6 style="font-weight:750; font-size: 16px;">Bank Info </h6></div>


            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-tooltip-edit mr-2"></i> -->
                    Bank Name <span class="text text-danger">*</span></label
                    >
                    <div class="input-group">
                        <!-- <input
                            v-model.trim="chaiPayBankDetails.bank_name"
                            type="text"
                            class="form-control"
                            placeholder="Bank Name"
                            name="bank_name"
                            
                            :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.bank_name.$error,
                        }"
                        /> -->
                        <b-form-select
                        name="bank_name"
                        v-model="chaiPayBankDetails.bank_name"
                        v-on:change="setbank"
                        class="form-control"
                        :class="{
                                'is-invalid':
                                typesubmit && $v.chaiPayBankDetails.bank_name.$error,
                            }"
                    >
                        
                    <option :key="index" v-for="(option, index) in this.bankList.content && this.bankList.content.data.master_code_list" :data-key="option.code" :value="option.label" >{{ option.label }}</option>

                    </b-form-select>
                        
                        <div
                        v-if="$v.chaiPayBankDetails.bank_name.$error"
                        class="invalid-feedback"
                        >
                        {{
                            "This field is required"
                        }}
                        </div>
                    </div>
                    <!-- <div
                            v-if="typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_email.$error"
                            class="invalid-feedback"
                            >
                            <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_email.email">{{ 'This is not valid email.' }}</span>
                            </div> -->
                </div>
            </div>

            

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Account Number<span class="text text-danger">*</span></label
                    >
                    <div class="input-group">
                        <input
                            v-model.trim="$v.chaiPayBankDetails.account_number.$model"
                            type="text"
                            class="form-control"
                            placeholder="Enter account number"
                            name="account_number"
                            :maxlength="max"
                            :class="{
                                'is-invalid':
                                typesubmit && $v.chaiPayBankDetails.account_number.$error,
                            }"
                        />
                        <div
                                class="input-group-addon"
                                v-text="max - chaiPayBankDetails.account_number.length"
                        ></div>
                        <div
                            v-if="$v.chaiPayBankDetails.account_number.$error"
                            class="invalid-feedback"
                        >
                        {{
                            "This Field is required"
                        }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group position-relative">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Account Type<span class="text text-danger">*</span></label
                    >
                    <b-form-select
                        name="account_type"
                        v-model="chaiPayBankDetails.account_type"
                        class="form-control"
                        :class="{
                                'is-invalid':
                                typesubmit && $v.chaiPayBankDetails.account_type.$error,
                            }"
                    >
                        <b-form-select-option value="SAVING">{{
                            "SAVING"
                        }}</b-form-select-option>
                        <b-form-select-option value="CURRENT">{{
                            "CURRENT"
                        }}</b-form-select-option>
                        <b-form-select-option value="CHECKING">{{
                            "CHECKING"
                        }}</b-form-select-option>
                        <b-form-select-option value="INDIVIDUAL">{{
                            "INDIVIDUAL"
                        }}</b-form-select-option>
                        <b-form-select-option value="CORPORATE">{{
                            "CORPORATE"
                        }}</b-form-select-option>
                    </b-form-select>
                    <div
                        v-if="$v.chaiPayBankDetails.account_type.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "This field is required"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    IFSC Code</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.ifsc.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter ifsc"
                        name="ifsc"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.ifsc.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.ifsc.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "This field is required"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Swift Code</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.swift.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter swift"
                        name="swift"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.swift.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.swift.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Branch</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.branch.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter branch"
                        name="branch"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.branch.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.branch.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>

        <div class="my-2 ml-2"><h6 style="font-weight:750; font-size: 16px;">International Transfer Info </h6></div>
            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Tansit Code</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.transit_code.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter transit code"
                        name="transit_code"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.transit_code.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.transit_code.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Sort Code</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.sort_code.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter sort code"
                        name="sort_code"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.sort_code.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.sort_code.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Bsb Code</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.bsb_code.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter bsb code"
                        name="bsb_code"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.bsb_code.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.bsb_code.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>


            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Routing Number</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.routing_number.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter routing number"
                        name="routing_number"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.routing_number.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.routing_number.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>


            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Iban</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.iban.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter iban"
                        name="iban"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.iban.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.iban.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Wing Money Number</label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.wing_money_number.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter wing money number"
                        name="wing_money_number"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.wing_money_number.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.wing_money_number.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label>
                    <!-- <i class="mdi mdi-account mr-2"></i> -->
                    Card Number </label
                    >
                    <input
                        v-model.trim="$v.chaiPayBankDetails.card_number.$model"
                        type="text"
                        class="form-control"
                        placeholder="Enter card number"
                        name="card_number"
                        :class="{
                            'is-invalid':
                            typesubmit && $v.chaiPayBankDetails.card_number.$error,
                        }"
                    />
                    <div
                        v-if="$v.chaiPayBankDetails.card_number.$error"
                        class="invalid-feedback"
                    >
                    {{
                        "Max 30 characters are allowed"
                    }}
                    </div>
                </div>
            </div>
        

            <div
                class="col-lg-12 my-1 text-right bg-white"
                style="position: sticky; bottom: 0px; padding-bottom: 15px"
            >
                <div
                    style="
                    background: #fff;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0px;
                    opacity: 0.5;
                    "
                ></div>
                <div style="width: 90%; margin: 0 auto; position: relative">
                    <button
                        style="width: 100%"
                        type="submit"
                        class="btn btn-primary"
                    >
                    Add
                    </button>
                    <button
                        style="width: 100%; margin-top: 10px"
                        @click="closeBankAccountModal"
                        id="close-payment-link-form"
                        class="btn btn-light mr-2"
                    >
                    {{ $t("button.close") }}
                    </button>
                </div>
            </div>
        </form>
        
    </div>
</template>

<style>
.xmx-datepicker-popup{
  z-index: 9999;
}
</style>

<style lang="css" scoped>
.checkbox-group-custom{
  position: relative;
}
.checkbox-group-custom input{
  display: none;
}
.checkbox-group-custom  label {
  cursor: pointer;
}
.checkbox-group-custom{
  display: inline-block;
}
.checkbox-group-custom  label {
  cursor: pointer;
}
.checkbox-group-custom  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #EAEAEA;
  border-radius: 4px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group-custom  label.filled:before {
  background-color: #252B3A;
}
.checkbox-group-custom label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.cursor-pointer{
  cursor: pointer;
}
::v-deep .billing_details_billing_email.is-valid {
  border-color: #ced4da;
  background-image: none;
  box-shadow: none;
}
::v-deep #VuePhoneNumber .resize-observer{
  display: none;
}
::v-deep .flag_images{
  position: absolute;
  width: 20px;
  top: 42px;
  left: 10px;
}
::v-deep .custom-select-for-flag{
  padding-left:35px ;
}
::v-deep .input-group-addon{
   padding: 10px 10px;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    background-color: #ecf0f1;
    border: 1px solid #dce4ec;
    border-left-color: transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
::v-deep .xmx-datepicker{
  width: 100%;
}
::v-deep .custom-datepicker .xmx-input{
  height: 35px !important;
  padding-top: 4px;
  min-height: unset;
}
</style>